import React from 'react'
import styled from 'styled-components'
import { color, breakpoint, transition } from '../../../configs/utilities'
import { Link } from 'gatsby-plugin-react-intl'

const ButtonLink = (props) => {
  return props.external ? (
    <ExternalLink href={props.to} target="_blank" rel="noopener noreferrer">
      {props.buttonText}
    </ExternalLink>
  ) : (
    <InternalWrapper>
      <InternalLink to={props.to}>{props.buttonText}</InternalLink>
    </InternalWrapper>
  )
}

export default ButtonLink

const InternalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
  a {
    color: ${color.white};
    background: ${color.gray};
    border: 1px solid ${color.blackLight};
    min-width: 110px;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 1px;
    box-shadow: 0px 4px 8px rgba(15, 16, 19, 0.6);
    cursor: pointer;
    font-size: 14px;
    transition: ${transition.default};

    &:hover {
      opacity: 0.7;
      border: 1px solid ${color.green};
    }

    @media screen and (max-width: ${breakpoint.xsm}) {
      font-size: 5vw;
    }

    @media screen and (min-width: ${breakpoint.md}) {
      min-width: 140px;
      padding: 12px 30px;
      font-size: 16px;
    }
  }
`

const InternalLink = styled(Link)``

const ExternalLink = styled.a`
  cursor: pointer;
  color: ${color.green};
  font-size: 16px;
  transition: ${transition.default};
  margin-top: 30px;
  &:hover {
    opacity: 0.7;
  }
`
