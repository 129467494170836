import React from 'react'
import styled from 'styled-components'
import ButtonLink from '../../components/common/buttons/ButtonLink'
import { SectionWrapper } from '../../components/common/wrappers/Wrappers'
import { breakpoint } from '../../configs/utilities'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { PageTitle, SectionDescription } from '../../components/common/typography/Typography'

const ErrorSection = ({ intl }) => {
  return (
    <SectionWrapper>
      <Wrapper>
        <PageTitle>{intl.formatMessage({ id: 'error.ops' })}</PageTitle>
        <SectionDescription>{intl.formatMessage({ id: 'error.text' })}</SectionDescription>
        <ButtonLink to='/' buttonText={intl.formatMessage({ id: 'error.buttonText' })}/>
      </Wrapper>
    </SectionWrapper>
  )
}

export default injectIntl(ErrorSection)

const Wrapper = styled.div`
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  text-align: center;
  @media screen and (min-width: ${breakpoint.md}) {
  min-height: 48vh;

  }

  @media screen and (min-width: ${breakpoint.xl}) {
  min-height: 56vh;
  row-gap: 50px;

  }
`


