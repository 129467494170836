import React from 'react'
import Layout from '../components/layout/Layout'
import styled from 'styled-components'
import { breakpoint } from '../configs/utilities'
import ErrorSection from '../sections/error/ErrorSection'

const Error = () => {
  return (
    <Layout pageName="error">
      <Wrapper>
        <ErrorSection />
      </Wrapper>
    </Layout>
  )
}

export default Error

const Wrapper = styled.div`
  @media screen and (min-width: ${breakpoint.xl}) {
  }
`
